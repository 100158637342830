<template>
  <v-row>
    <v-col
      md="9"
      cols="12"
    >
      <v-card
        v-if="previewMode"
        ref="pdfContent"
        elevation="4"
      >
        <!-- FIRST BLOCK -->
        <v-card-text class="d-flex justify-space-between">
          <div class="mb-6">
            <company-infos-bloc></company-infos-bloc>
          </div>
          <div class="mb-3">
            <h6
              class="d-flex justify-space-between font-weight-medium text-xl"
            >
              <span class="me-3">Facture :</span>
              <span class="">
                {{ invoice.number }}
              </span>
            </h6>
            <p
              class="d-flex justify-space-between  justify-end"
            >
              <span class="me-3">Date de facturation :</span>
              <span class="">
                {{ new Date(invoice.invoiceDate) | date('dd/MM/yyyy') }}
              </span>
            </p>
            <p
              class="d-flex  justify-space-between justify-end"
            >
              <span class="me-3">Date d'échéance :</span>
              <span class="">
                {{ new Date(invoice.limitDate) | date('dd/MM/yyyy') }}
              </span>
            </p>
          </div>
        </v-card-text>
        <!-- END FIRST BLOCK -->
        <v-divider></v-divider>
        <!-- SECOND BLOCK -->
        <v-card-text class="d-flex justify-space-between">
          <div class="mb-6">
            <p class="text-sm font-weight-medium mb-3">
              Facturé à :
            </p>
            <person-address :person="invoice.to"></person-address>
          </div>
          <div class="mb-3">
            <p class="text-sm font-weight-medium mb-3">
              Informations de facturation :
            </p>
            <company-rib-table></company-rib-table>
          </div>
        </v-card-text>
        <!-- END SECOND BLOCK -->
        <v-divider></v-divider>
        <!-- THIRD BLOCK -->
        <invoice-builder
          :preview="previewMode"
          :tva="invoice.tva"
        >
        </invoice-builder>
        <!-- END THIRD BLOCK -->
        <v-divider></v-divider>
        <!-- FOURTH BLOCK -->
        <v-card-text
          v-if="note.length > 0"
          class="d-flex flex-column"
        >
          <p class="font-weight-semibold mb-2">
            Note :
          </p>
          <span
            class="linepre"
            v-html="note"
          >

          </span>
        </v-card-text>
        <!-- END THIRD BLOCK -->
      </v-card>
      <v-card
        v-else
        elevation="4"
      >
        <!-- FIRST BLOCK -->
        <v-card-text class="d-flex justify-space-between">
          <div class="mb-6">
            <company-infos-bloc></company-infos-bloc>
          </div>
          <div class="mb-3">
            <h6
              class="d-flex align-self-center font-weight-medium justify-sm-end text-xl"
            >
              <span class="me-3">Facture :</span>
              <span>
                <v-text-field
                  v-model="invoice.number"
                  class="rem9"
                  dense
                  outlined
                  label="Numéro de facture"
                ></v-text-field>
              </span>
            </h6>
            <p
              class="d-flex justify-sm-end"
            >
              <span class="me-3">Date de facturation :</span>
              <span>
                <v-text-field
                  v-model="invoice.invoiceDate"
                  class="rem9"
                  type="date"
                  dense
                  outlined
                  label="Numéro de facture"
                ></v-text-field>
              </span>
            </p>
            <p
              align="center"
              class="d-flex justify-sm-end"
            >
              <span class="me-3">Date d'échéance :</span>
              <span>
                <v-text-field
                  v-model="invoice.limitDate"
                  type="date"
                  class="rem9"
                  dense
                  outlined
                  label="Numéro de facture"
                ></v-text-field>
              </span>
            </p>
          </div>
        </v-card-text>
        <!-- END FIRST BLOCK -->
        <v-divider></v-divider>
        <!-- SECOND BLOCK -->
        <v-card-text class="d-flex justify-space-between">
          <div class="mb-6">
            <p class="text-sm font-weight-medium mb-3">
              Facturé à :
            </p>
            <invoice-to-chooser @toUpdate="(payload) => invoice.to = payload"></invoice-to-chooser>
          </div>
          <div class="mb-3">
            <p class="text-sm font-weight-medium mb-3">
              Informations de facturation :
            </p>
            <company-rib-table></company-rib-table>
          </div>
        </v-card-text>
        <!-- END SECOND BLOCK -->
        <v-divider></v-divider>
        <!-- THIRD BLOCK -->
        <invoice-builder
          :tva="invoice.tva"
          @updateItems="(payload) => invoice.items = payload"
        >
        </invoice-builder>
        <!-- END THIRD BLOCK -->
        <v-divider></v-divider>
        <!-- FOURTH BLOCK -->
        <v-card-text class="d-flex flex-column ">
          <p class="font-weight-semibold mb-2">
            Note :
          </p>
          <v-textarea
            v-model="invoice.note"
            outlined
          >
          </v-textarea>
        </v-card-text>
        <!-- END THIRD BLOCK -->
      </v-card>
    </v-col>
    <v-col
      md="3"
      cols="12"
    >
      <v-card
        elevation="4"
        class="sticky-top"
      >
        <v-card-text>
          <div class="d-flex align-center justify-space-between">
            <label for="tvaSwitch">
              TVA (20%)
            </label>
            <v-switch v-model="invoice.tva"></v-switch>
          </div>
          <div class="d-flex align-center justify-space-between">
            <label for="tvaSwitch">
              TVA
            </label>
            <v-switch v-model="invoice.tva"></v-switch>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            color="primary"
            dark
            outlined
            @click="previewMode = !previewMode"
          >
            Prévisualiser
          </v-btn>
        </v-card-actions>

        <v-card-actions>
          <v-btn
            block
            color="primary"
            dark
            @click="saveInvoice"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            block
            color="primary"
            dark
            @click="generatePdf"
          >
            Télécharger
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CompanyInfosBloc from '@/components/CompanyInfosBloc.vue'
import InvoiceToChooser from '@/components/InvoiceToChooser.vue'
import PersonAddress from '@/components/PersonAddress.vue'
import CompanyRibTable from '@/components/CompanyRibTable.vue'
import InvoiceBuilder from '@/components/InvoiceBuilder.vue'
import { mdiBriefcase } from '@mdi/js'

export default {
  components: {
    CompanyInfosBloc,
    InvoiceToChooser,
    PersonAddress,
    CompanyRibTable,
    InvoiceBuilder,
  },
  metaInfo: {
    title: 'Nouvelle facture',
    titleTemplate: '%s - Jimbo',
  },
  data: () => ({
    invoice: {
      number: 0,
      invoiceDate: new Date(),
      limitDate: new Date(),
      to: {
      },
      items: [],
      note: '',
      tva: false,
    },
    previewMode: false,
    icons: {
      mdiBriefcase,
    },
  }),
  methods: {
    saveInvoice() {
      this.$http.post(`${process.env.VUE_APP_API_URL}/invoice/new`, this.invoice)
        .then(res => {
          if (res.status === 200) {
            this.$store.commit('snackMe', {
              color: 'success',
              text: 'Facture sauvegardée avec succès',
              value: true,
            })
          }
        })
        .catch(err => console.log(err))
        .finally()
    },
    generatePdf() {
      this.$http.post(`${process.env.VUE_APP_API_URL}/invoice/new`, this.invoice)
        .then(res => {
          if (res.status === 200) {
            this.$http.get(`${process.env.VUE_APP_API_URL}/invoice/pdf-generate/${res.data._id}`)
              .then(pdf => {
                window.open(`${process.env.VUE_APP_API_URL}/${pdf.data}`, '_blank')
                console.log(pdf)
              })
              .catch(err => console.log(err))
              .finally()
          }
        })
        .catch(err => console.log(err))
        .finally()
    },
  },

}
</script>

<style scoped>
.sticky-top{
  position: sticky;
  top: 25px;
}
.rem9 {
  width: 9rem;
}

</style>
