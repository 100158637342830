<template>
  <div>
    <v-card-text v-if="preview">
      <invoice-builder-preview
        :items="items"
        :tva="tva"
      ></invoice-builder-preview>
    </v-card-text>
    <v-card-text v-else>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="mb-4"
      >
        <div class="add-products-header mb-2 d-none d-md-flex">
          <v-row class="font-weight-medium mb-2">
            <v-col
              md="6"
              cols="12"
            >
              <h4>
                Objet
              </h4>
            </v-col>
            <v-col
              md="2"
              cols="12"
            >
              <h4>
                Prix unitaire
              </h4>
            </v-col>
            <v-col
              md="2"
              cols="12"
            >
              <h4>
                Unités
              </h4>
            </v-col>
            <v-col
              md="2"
              cols="12"
            >
              <h4>
                Total
              </h4>
            </v-col>
          </v-row>
        </div>
        <v-card
          outlined
          flat
          class="d-flex flex-sm-row flex-column-reverse"
        >
          <div class="pa-5 flex-grow-1">
            <v-row>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="items[index].title"
                  outlined
                  dense
                  label="Objet"
                >
                </v-text-field>
                <v-textarea
                  v-model="items[index].description"
                  dense
                  outlined
                  label="Description"
                >
                </v-textarea>
              </v-col>
              <v-col
                sm="4"
                md="2"
                cols="12"
              >
                <v-text-field
                  v-model="items[index].cost"
                  type="number"
                  label="Prix unitaire"
                  dense
                  outlined
                  suffix="€"
                >
                </v-text-field>

                <v-switch
                  v-model="items[index].discountEnabled"
                  label="Réduction"
                ></v-switch>
              </v-col>
              <v-col
                sm="4"
                md="2"
                cols="12"
              >
                <v-text-field
                  v-model="items[index].units"
                  dense
                  outlined
                  type="number"
                  label="Unités"
                >
                </v-text-field>
                <v-text-field
                  v-if="items[index].discountEnabled"
                  v-model="items[index].discount"
                  label="Réduction"
                  suffix="%"
                  min="0"
                  max="100"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col
                class="d-flex text-center flex-column mt-2"
                sm="4"
                md="2"
                cols="12"
              >
                <p
                  class="font-weight-bold"
                  :class="items[index].discountEnabled ? 'text-decoration-line-through' : ''"
                >
                  {{ lineSubTotal(index) }} €
                </p>
                <br>
                <p
                  v-if="items[index].discountEnabled"
                  class="font-weight-bold"
                >
                  - {{ lineDiscount(index) }} €
                </p>
                <p
                  v-if="items[index].discountEnabled"
                  class="font-weight-bold"
                >
                  {{ lineTotal(index) }} €
                </p>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex flex-column item-actions pa-1 border-s">
            <v-btn
              icon
              color="primary"
              @click="removeItem(index)"
            >
              <v-icon>{{ icons.mdiTrashCan }}</v-icon>
            </v-btn>
          </div>
        </v-card>
      </div>
      <v-btn
        color="success"
        @click="addItem"
      >
        Ajouter un objet
      </v-btn>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <invoice-builder-total-box
        :items="items"
        :tva="tva"
      >
      </invoice-builder-total-box>
    </v-card-text>
  </div>
</template>

<script>
import { mdiTrashCan } from '@mdi/js'
import InvoiceBuilderPreview from '@/components/InvoiceBuilderPreview.vue'
import InvoiceBuilderTotalBox from '@/components/InvoiceBuilderTotalBox.vue'

export default {
  components: {
    InvoiceBuilderPreview,
    InvoiceBuilderTotalBox,
  },
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
    tva: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        {
          title: '',
          description: '',
          cost: 0,
          units: 1,
          total: 0,
          discountEnabled: false,
          discount: null,
        },
      ],
      icons: {
        mdiTrashCan,
      },
    }
  },
  watch: {
    items: {
      handler(val) {
        if (val) {
          this.$emit('updateItems', val)
        }
      },
      deep: true,
    },
  },
  methods: {
    addItem() {
      this.items.push({
        title: '',
        description: '',
        cost: 0,
        units: 1,
        total: 0,
        discountEnabled: false,
        discount: null,
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    lineSubTotal(index) {
      const { units } = this.items[index]
      const { cost } = this.items[index]
      const total = units * cost

      return Number(total)
    },
    lineDiscount(index) {
      const { units } = this.items[index]
      const { cost } = this.items[index]
      const { discount } = this.items[index]

      const total = units * cost
      const discountCalculated = total * (discount / 100)

      return Number(discountCalculated)
    },
    lineTotal(index) {
      const { units } = this.items[index]
      const { cost } = this.items[index]
      const discount = this.lineDiscount(index)
      const total = (units * cost) - discount

      return Number(total)
    },
    lineTva(index) {
      const lineTotal = this.lineTotal(index)
      const total = lineTotal * 0.2

      return Number(total)
    },
  },
}
</script>

<style>

</style>
