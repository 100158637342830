<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
      @click:outside="$emit('closeDialog')"
      @keydown.esc="$emit('closeDialog')"
    >
      <v-card elevation="4">
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="person.companyName"
                  name="company"
                  label="Entreprise"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="person.lastName"
                  name="lastName"
                  label="Nom"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="person.firstName"
                  name="firstName"
                  label="Prenom"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-text-field
                  v-model="person.address.line1"
                  label="Adresse ligne 1"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col md="12">
                <v-text-field
                  v-model="person.address.line2"
                  label="Adresse ligne 2"
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <v-text-field
                  v-model="person.address.postcode"
                  label="Code Postal"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="7">
                <v-text-field
                  v-model="person.address.city"
                  label="Ville"
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="primary"
            dark
            @click="$emit('resetDialog')"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            dark
            @click="$emit('pickAddress', person)"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      person: {
        name: '',
        firstName: '',
        lastName: '',
        address: {
          line1: '',
          line2: '',
          postcode: '',
          city: '',
        },
      },
    }
  },

}
</script>

<style>

</style>
