<template>
  <div>
    <v-autocomplete
      v-model="selectedContact"
      dense
      outlined
      :items="contacts"
      return-object
      :search-input.sync="search"
      :item-text="(item)=> `${item.firstName} ${item.lastName}` "
      clearable
      @input="$emit('pickAddress', selectedContact)"
    >
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      selectedContact: null,
      contacts: [],
    }
  },
  watch: {
    search(val) {
      if (val) {
        this.searchContact(val)
      }
    },
  },
  methods: {
    searchContact(val) {
      this.$http.get(`${process.env.VUE_APP_API_URL}/contacts/search?query=${val}`)
        .then(res => {
          this.contacts = res.data
        })
        .catch(err => console.log(err))
        .finally()
    },
  },
}
</script>

<style>

</style>
