<template>
  <div>
    <div
      v-if="to.address"
      class="d-flex justify-space-between align-center"
    >
      <person-address
        :person="to"
      ></person-address>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-5"
        color="primary"
        icon
        outlined
        @click="resetAddress"
      >
        <v-icon>
          {{ icons.mdiPencil }}
        </v-icon>
      </v-btn>
    </div>
    <div v-else>
      <!-- TYPE CHOOSE -->
      <div class="mb-5">
        <v-btn-toggle
          v-model="type"
          tile
          mandatory
          color="primary"
          group
        >
          <v-btn value="bc">
            Entreprise
          </v-btn>

          <v-btn value="contact">
            Etudiant
          </v-btn>

          <v-btn value="free">
            Libre
          </v-btn>
        </v-btn-toggle>
      </div>
      <!-- END TYPE CHOOSE -->
      <div
        v-if="type ==='bc'"
      >
        <business-contact-autocomplete @businessContactSelected="renderAddress"></business-contact-autocomplete>
      </div>
      <div
        v-else-if="type ==='contact'"
      >
        <contact-autocomplete @pickAddress="renderAddress"></contact-autocomplete>
      </div>
    </div>
    <address-form-dialog
      :dialog="afDialog"
      @resetDialog="resetDialog"
      @closeDialog="afDialogClosed"
      @pickAddress="renderAddress"
    ></address-form-dialog>
  </div>
</template>

<script>
import BusinessContactAutocomplete from '@/components/BusinessContactAutocomplete.vue'
import ContactAutocomplete from '@/components/ContactAutocomplete.vue'
import AddressFormDialog from '@/components/AddressFormDialog.vue'
import PersonAddress from '@/components/PersonAddress.vue'

import { mdiPencil } from '@mdi/js'

export default {
  components: {
    BusinessContactAutocomplete,
    ContactAutocomplete,
    AddressFormDialog,
    PersonAddress,
  },
  data() {
    return {
      type: 'bc',
      to: {
        address: null,
      },
      afDialog: false,
      selectedBc: null,
      search: null,
      contact: [],
      icons: {
        mdiPencil,
      },
    }
  },
  watch: {
    type(val) {
      if (val) {
        if (val === 'free') {
          this.afDialog = true
        }
      }
    },
    to(val) {
      if (val) {
        val.type = this.type
        this.$emit('toUpdate', val)
      }
    },
  },
  methods: {
    resetAddress() {
      if (this.type === 'free') {
        this.afDialog = true
      } else {
        this.to.address = null
      }
    },
    resetDialog() {
      this.afDialog = false
      this.type = 'bc'
      this.to.address = null
    },
    afDialogClosed() {
      this.afDialog = false
      this.type = 'bc'
    },
    renderAddress(payload) {
      this.afDialog = false
      this.to = { ...payload }
    },
  },
}
</script>

<style>

</style>
