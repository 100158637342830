<template>
  <div>
    <v-autocomplete
      v-model="selectedBc"
      dense
      outlined
      :items="businessContacts"
      return-object
      :search-input.sync="search"
      item-text="name"
      clearable
      @input="$emit('businessContactSelected', selectedBc)"
    >
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      selectedBc: null,
      businessContacts: [],
    }
  },
  watch: {
    search(val) {
      if (val) {
        this.searchBc(val)
      }
    },
  },
  methods: {
    searchBc(val) {
      this.$http.get(`${process.env.VUE_APP_API_URL}/business-contact/search?query=${val}`)
        .then(res => {
          this.businessContacts = res.data
        })
        .catch(err => console.log(err))
        .finally()
    },
  },
}
</script>

<style>

</style>
